import { graphql } from "gatsby"
import { MatchedGiftPage } from "@pageTemplates"

export default MatchedGiftPage

export const pageQuery = graphql`
  query Gifts {
    matchedGiftProgramJson {
      hero {
        title
        image {
          src {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
            publicURL
          }
          alt
        }
      }
      description {
        paragraph
      }
      giftQualifications {
        title
        qualifications {
          item
        }
      }
      fields {
        slug
      }
    }
  }
`
